export const getDesignTheme = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode..
          primary: {
            ultra: "#B3BFFF",
            light: "#93a5ff",
            main: "#556ee6",
            dark: "#485ec4",
          },
          secondary: {
            light: "#ffcc38",
            main: "#ffbe02",
            dark: "#e8ac00",
          },
          contras: {
            main: "#000000",
          },
          current: {
            main: "#ffffff",
          },
          disable: {
            light: "#8b8e9d",
            main: "#74788d",
            main2: "#8888884d",
            dark: "#636678",
            contrastText: "#fff",
          },
          background: {
            default: "#f8f8fb",
            dark: "#2a3042",
            navBar: "#ffffff",
            card: "#d4dbf9",
            card2: "#eff2f7",
            sideBar: "#EFEFEF",
            sideBar2: "#DfDfDf",
          },
          icon: {
            green: "#6fb33c",
            dark: "#069e10",
            warning: "#f1b44c",
            error: "#f46a6a",
            sidebar: "#a6b0cf",
            sidebar1: "#ffffff",
            fieldErr: "#d32f2f",
          },
          text: {
            head: "#000",
            normal: "#00000099",
            primary: "#36383b",
            secondary: "#74788d",
            contrasPrimary: "#ffffff",
            contrasSecondary: "#ffffff88",
            sidebar: "#7c7c7c",
          },
          border: {
            main: "#eff2f7",
            primary: "#495057",
            scroll: " #666e7c",
          },
          shadow: {
            navBar: "#12263f08",
          },
          attendance: {
            present: "#3CCF4E",
            leave: "#EF5B0C",
            absent: "#C21010",
            sports: "#277BC0",
            medical: "#1CD6CE",
            "first half": "#F6A192",
            half: "#F6A192",
            "second half": "#EA6823",
            chip: "#00000014",
            light: "#93a5ff",
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#EA6823",
          },
          secondary: {
            main: "#000",
          },
          background: {
            default: "#222736",
          },
        }),
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  selected: {},
});
